.filter-tree {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    flex-grow: 1;
  }
}
