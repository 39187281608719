@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

.mapboxgl-marker {
  cursor: pointer;
}

@import './components/TicketsMap/tickets-map';
@import './components/FilterTree/FilterTree';
